const ValidationCheck = validationList => {
  let counter = 0;
  // AdHoc solution
  // validationList is excpected to be [true,false]
  if (!validationList[0]) counter += 1;
  if (validationList[1]) counter += 1;

  // I allow 2 validations to be wrong
  return counter < 2;
};

export default ValidationCheck;
