import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import uuidv4 from 'uuid/v4';
import axios from 'axios';
import Fingerprint2 from 'fingerprintjs2';
import fisher from '../components/fisherYates';
import EraseCard from '../components/eraseCard';

import {
  IMAGES_TO_VOTE,
  ERROR_MESSAGES,
  BASE_URL,
} from '../components/constants';
import ValidationCheck from '../components/validationCheck';
import ErrorFileSaving from '../components/errorHandle';
// Style import
import '../styles/global.scss';
import VotingCard from '../components/votingCards';
import ValidationCard from '../components/validationCard';

class Main extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    /**
     * Checking if the UUID exists in the location props
     * in order to store is (UUID is sent with ClickWorkers)
     */
    const { location } = props;
    const stateExists = Object.prototype.hasOwnProperty.call(location, 'state');
    let uuid = null;
    if (stateExists) {
      if (location.state != null) {
        uuid = Object.prototype.hasOwnProperty.call(location.state, 'uuid')
          ? location.state.uuid
          : null;
      }
    }
    // ////////////////////////////////////////////

    /**
     * Here I am fetching all the images from a json object with GraphQL
     * Then I shuffle them with Fisher Yates algorithm and take the first IMAGES_TO_VOTE images
     */
    const { data } = this.props;
    const imageList = data.allDataJson.edges[0].node.images;
    const images = fisher(imageList).splice(0, IMAGES_TO_VOTE);
    // ////////////////////////////////////////////

    /**
     * Now I am generating all the Components I have to render , in order to loop them
     * after with a pointer
     */
    const actions = [];
    for (let i = 0; i < images.length; i += 2) {
      actions.push(['vote', ...images.slice(i, i + 2)]);
      actions.push(['erase']);
      if (i === 10) {
        // I except those Validation blocks to return True
        actions.push(['validate', images[i - 3]]);
      } else if (i === 30) {
        // I except those Validation blocks to return False
        actions.push(['validate', images[i + 30]]);
      }
    }

    this.state = {
      voting: [],
      validation: [],
      pointer: 0,
      timeErrors: 0,
      actions,
      uuid,
    };
  }

  componentDidMount() {
    console.log('Just Mounted ⛰');
    // Saving the Fingerprint to state
    if (window.requestIdleCallback) {
      requestIdleCallback(() => {
        Fingerprint2.get(components => {
          const values = components.map(function(component) {
            return component.value;
          });
          const murmur = Fingerprint2.x64hash128(values.join(''), 31);
          this.setState({ fingerprint: murmur });
        });
      });
    } else {
      setTimeout(() => {
        Fingerprint2.get(components => {
          const values = components.map(function(component) {
            return component.value;
          });
          const murmur = Fingerprint2.x64hash128(values.join(''), 31);
          this.setState({ fingerprint: murmur });
        });
      }, 500);
    }
  }

  // Increase the counter of timeErrors
  // That means that the user did not give an answer in 6 seconds
  increaseErrorPointer = () => {
    let { timeErrors } = this.state;
    timeErrors += 1;
    this.setState({ timeErrors });
  };

  // Handle every voting
  // Data consists of
  // ["image1","image2",selection]
  // If selection is 2 then I don't push the data and I increase the error pointer!
  handleVote = data => {
    if (data[2] === 2) {
      // The users was late to vote
      console.log('Increased Error Pointer');
      this.increaseErrorPointer();
    }
    const { voting } = this.state;
    let { pointer } = this.state;
    voting.push(data);
    pointer += 1;
    this.setState({
      voting,
      pointer,
    });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  // Handle every validation
  // Data consists of
  // Boolean variable (true if yes false if no is pressed)
  // I have hardcoded the output and I excpect a
  // result of [true,false,false,true]
  handleValidation = data => {
    const { validation } = this.state;
    let { pointer } = this.state;
    validation.push(data);
    pointer += 1;
    this.setState({
      validation,
      pointer,
    });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };

  changeBlock = () => {
    let { pointer } = this.state;
    setTimeout(() => {
      pointer += 1;
      this.setState({ pointer });
    }, 200);
  };

  sendFinish = uuid => {
    const data = { ...this.state };
    if (data.timeErrors > 4) {
      alert(ERROR_MESSAGES.slow);
      navigate('/error', { state: { message: ERROR_MESSAGES.slow } });
      return;
    }
    if (!ValidationCheck(data.validation)) {
      alert(ERROR_MESSAGES.validation);
      navigate('/error', { state: { message: ERROR_MESSAGES.validation } });
      return;
    }
    // sto /analysis/voting/
    const bodyFormData = new FormData();
    bodyFormData.set('fingerprint', data.fingerprint);
    bodyFormData.set('uuid', uuid);
    bodyFormData.set('votes', data.voting);
    bodyFormData.set('validation', data.validation);

    axios({
      method: 'post',
      url: `${BASE_URL}/api/`,
      data: bodyFormData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    })
      .then(function(response) {
        alert('Your Voting is Finished');
      })
      .catch(function(error) {
        alert(
          'An error occured with our servers. In order to continue with the procedure fill free to send us the downloaded file "error.json" to info@loceye.io'
        );
        const errors = {
          uuid: data.uuid,
          votings: data.voting,
          description: 'Send us this file to info@loceye.io',
        };
        ErrorFileSaving(errors);
      });
  };

  render() {
    const { pointer, actions } = this.state;

    const finish = pointer === actions.length;

    let block;
    let { uuid } = this.state;
    if (finish) {
      if (!uuid) {
        uuid = uuidv4();
      }
      this.sendFinish(uuid);
    } else {
      switch (actions[pointer][0]) {
        case 'vote':
          block = (
            <VotingCard
              handleVote={this.handleVote}
              images={actions[pointer].slice(1, 3)}
            />
          );
          break;
        case 'validate':
          block = (
            <ValidationCard
              handleValidation={this.handleValidation}
              image={actions[pointer][1]}
            />
          );
          break;
        case 'erase':
          this.changeBlock();
          block = <EraseCard />;
          break;
        default:
          break;
      }
    }

    return (
      <div>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Paper className="intro">
              <div className="Layout">
                {finish ? (
                  <div>
                    <Typography
                      className="title"
                      component="h1"
                      variant="h4"
                      gutterBottom
                    >
                      Great we finished the voting! Thank you for your time.
                    </Typography>
                    <Typography
                      className="title"
                      component="h1"
                      variant="h6"
                      gutterBottom
                    >
                      Grap your unique code and paste it to the Amazon Turk
                      panel in order to identify you!
                    </Typography>
                    <Typography
                      className="title"
                      component="h1"
                      variant="h6"
                      gutterBottom
                    >
                      {uuid}
                    </Typography>
                    <Typography
                      className="title"
                      component="h1"
                      variant="h6"
                      gutterBottom
                    >
                      If you are coming from ClickWorkers paste the following
                      code:
                    </Typography>
                    <Typography
                      className="title"
                      component="h1"
                      variant="h6"
                      gutterBottom
                    >
                      click15testvotes
                    </Typography>
                  </div>
                ) : (
                  block
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Main.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Main;

export const query = graphql`
  {
    allDataJson {
      edges {
        node {
          images
        }
      }
    }
  }
`;
