import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';

// Style import
import '../styles/global.scss';

class VotingCard extends React.Component {
  constructor(props) {
    super(props);
    const { images, handleVote } = this.props;
    this.state = {
      seconds: null,
      timerFunc: null,
      image1: null,
      image2: null,
      images,
      handleVote,
    };
  }

  componentDidMount() {
    const { images } = this.state;
    console.log('Voting Component Mounted');
    /**
     * Hack to remove all timeIntervals
     * source: https://stackoverflow.com/questions/8635502/how-do-i-clear-all-intervals
     */
    const intervalId = window.setInterval(function() {}, 9999);
    for (let i = 1; i < intervalId; i += 1) {
      console.log('[CLEANING THE INTERVALS]');
      window.clearInterval(i);
    }
    this.downloadImages(images);
  }

  componentWillReceiveProps(nextProps) {
    const { images: oldImages, handleVote } = this.props;
    const { images } = nextProps;
    if (images !== oldImages) {
      const { timerFunc } = this.state;
      console.log('I am updating due to new props');
      clearInterval(timerFunc);
      this.state = {
        seconds: null,
        timerFunc: null,
        image1: null,
        image2: null,
        images,
        handleVote,
      };
      this.downloadImages(images);
    }
  }

  componentWillUnmount() {
    console.log('I am unmounting!');
    const { timerFunc } = this.state;
    if (timerFunc != null) {
      clearInterval(timerFunc);
    }
  }

  /**
   * Timer Function
   * Input: duration in seconds
   * I am saving the interval in state, also the seconds, and when the component is unmounted,
   * I clear the interval on componentWillUnmount call
   */
  startTimer = duration => {
    let timer = duration;
    let seconds;
    const timerFunc = window.setInterval(() => {
      const { handleVote } = this.state;
      seconds = parseInt(timer % 60, 10) - 1;

      this.setState({ seconds });
      if (timer - 1 < 0) {
        // Now the time have passed so I will call an unmount :D
        handleVote([0, 0, 2]);
        clearInterval(timerFunc);
        console.log('More then 6 seconds passed!');
      }
      timer -= 1;
    }, 1000);
    this.setState({ timerFunc });
  };

  /**
   * Async Download of the images
   * Input: \[image1,image2]
   * Alternative: https://d11q73zh85cgud.cloudfront.net/static/landingDataset/
   */
  downloadImages = images => {
    axios
      .get(
        `https://s3-us-west-2.amazonaws.com/loceye-production/static/landingDataset/${
          images[0]
        }`,
        {
          responseType: 'arraybuffer',
          'Access-Control-Allow-Origin': '*',
        }
      )
      .then(response => {
        const base64 = `data:image/jpeg;base64,${Buffer.from(
          response.data,
          'binary'
        ).toString('base64')}`;
        this.setState({ image1: base64 });
      });
    axios
      .get(
        `https://s3-us-west-2.amazonaws.com/loceye-production/static/landingDataset/${
          images[1]
        }`,
        {
          responseType: 'arraybuffer',
          'Access-Control-Allow-Origin': '*',
        }
      )
      .then(response => {
        const base64 = `data:image/jpeg;base64,${Buffer.from(
          response.data,
          'binary'
        ).toString('base64')}`;
        this.setState({ image2: base64 });
        this.startTimer(6);
      });
  };

  render() {
    const { handleVote, images } = this.props;
    const { seconds, image1, image2 } = this.state;

    const downloaded = image1 != null && image2 != null;

    return (
      <Fragment>
        <Typography className="title" component="h1" variant="h4" gutterBottom>
          Which design is feels clearer?
        </Typography>
        {downloaded ? (
          <Typography variant="subtitle2" gutterBottom>
            Time remaining: {seconds}
          </Typography>
        ) : null}

        <Grid container spacing={24}>
          {downloaded ? (
            <Fragment>
              <Grid item xs={6}>
                <Card className="card">
                  <img src={image1} className="newsletter vote" alt="vote1" />
                  <CardActions className="fitt-right">
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => handleVote([...images, 0])}
                    >
                      Choose this
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card className="card">
                  <img src={image2} className="newsletter vote" alt="vote2" />
                  <CardActions>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => handleVote([...images, 1])}
                    >
                      Choose this
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Fragment>
          ) : (
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Wait while the images are loading
              </Typography>
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  }
}

VotingCard.propTypes = {
  handleVote: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
};

export default VotingCard;
