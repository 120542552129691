import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

// Style import
import '../styles/global.scss';

class ValidationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Hack to remove all timeIntervals
     * source: https://stackoverflow.com/questions/8635502/how-do-i-clear-all-intervals
     */
    const intervalId = window.setInterval(function() {}, 9999);
    for (let i = 1; i < intervalId; i += 1) {
      console.log('[CLEANING THE INTERVALS]');
      window.clearInterval(i);
    }
  }

  render() {
    const { handleValidation, image } = this.props;
    return (
      <div>
        <Typography className="title" component="h1" variant="h4" gutterBottom>
          Have you seen this Landing page so far?
        </Typography>
        <Grid
          container
          spacing={24}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={8}>
            <Card className="card">
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                className="newsletter"
                image={`https://d11q73zh85cgud.cloudfront.net/static/landingDataset/${image}`}
                title="Contemplative Reptile"
              />
              <CardActions>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => handleValidation(true)}
                >
                  Yes
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  onClick={() => handleValidation(false)}
                >
                  No
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ValidationCard.propTypes = {
  handleValidation: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
};

export default ValidationCard;
