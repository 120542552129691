const DEBUG = false;

export const BASE_URL = DEBUG
  ? 'http://localhost:8001'
  : 'https://voting-dataset.herokuapp.com';

export const IMAGES_TO_VOTE = 200;

export const ERROR_MESSAGES = {
  slow:
    'You were slower than 6 seconds to more than 4 choices. Therefore your application is not submitted.',
  validation:
    'You chose wrong answers in the validation process. Therefore your application is not submitted.',
};
