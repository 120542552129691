import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LazyImage from 'react-lazy-progressive-image';

// Style import
import '../styles/global.scss';

const EraseCard = () => (
  <Fragment>
    <Typography className="title" component="h1" variant="h4" gutterBottom>
      Which design is feels clearer?
    </Typography>

    <Grid container spacing={24} />
  </Fragment>
);
export default EraseCard;
